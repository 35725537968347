<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/overhaulRecord' }">检修记录</el-breadcrumb-item>
          <el-breadcrumb-item>记录详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form v-if="form.baseInfo" :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div style="margin-bottom: 30px;">
            <el-steps :active="active">
              <el-step title="新检修计划"></el-step>
              <el-step title="检修过程中"></el-step>
              <el-step title="检修审核"></el-step>
              <span style="line-height:50px; font-size: 30px; margin-left: 30px; color: #f56c6c;" v-if="form.baseInfo.status == 2">未解决</span>
              <span style="line-height:50px; font-size: 30px; margin-left: 30px; color: #67c23a;" v-if="form.baseInfo.status == 4">已解决</span>
            </el-steps> 
          </div>
          <div class="form-title">
            基本信息
            <a :href="'/statistics/wapi/v1/file/export/device/report?archivesId='+form.archivesId" target="_blank"><el-button style="margin-left: 10px;float:right" type="primary" size="mini">导出报告</el-button></a>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="检修代号：">{{form.baseInfo.order}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="来源：">{{form.baseInfo.source == 0 ? '新创建' : form.baseInfo.source == 1 ? '巡检' : '点检'}}</el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">设备信息</div>
          <div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备别名：">{{form.patrolDeviceInfo.alias}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备识别号：">{{form.patrolDeviceInfo.ein?form.patrolDeviceInfo.ein:'无'}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设施位置：">{{form.patrolDeviceInfo.facilityName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="巡视点名称：">{{form.patrolDeviceInfo.patrolName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备名称：">{{form.patrolDeviceInfo.deviceName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备类型：">{{form.patrolDeviceInfo.deviceTypeName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="所属部门：">{{form.patrolDeviceInfo.departmentName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="规格参数：">{{form.patrolDeviceInfo.specification}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="投入使用日期：" label-width="130px">
                  {{ form.patrolDeviceInfo.applyTime ? dayjs(form.patrolDeviceInfo.applyTime).format('YYYY-MM-DD'):'' }}
                </el-form-item>
              </el-col>
              <el-col :span="12"></el-col>
            </el-row>
          </div>
          <div v-if="form.baseInfo.source != 0">
            <div class="form-title">问题文字描述</div>
            <el-form-item label-width="20px">{{form.abnormalTaskInfo.describeContent?form.abnormalTaskInfo.describeContent:'（空）'}}</el-form-item>
            <div class="form-title">巡检工作图片</div>
            <div class="img-box" v-if="form.abnormalTaskInfo.fileUrls.length>0">
              <el-image 
                class="images"
                v-for="(item,index) in form.abnormalTaskInfo.fileUrls"
                :key="index"
                :src="item"
                :preview-src-list="[item]">
              </el-image>
            </div>
            <div v-else>
              <el-form-item label-width="20px">（空）</el-form-item>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="巡检人：">{{form.abnormalTaskInfo.submitter ? form.abnormalTaskInfo.submitter : '无'}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="提交时间：">
                    {{ form.abnormalTaskInfo.completeTime ? dayjs(form.abnormalTaskInfo.completeTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- 检修人员配置模块 -->
          <el-collapse v-model="activeNames">
            <el-collapse-item :title="`检修配置 ${index+1}`" :name="`${index+1}`" v-for="(item,index) in form.elementInfos" :key="item.eid">
              <div style="padding: 20px;">
                <div class="form-title config-title">检修人员配置</div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="检修人员">
                      <div class="staffBox">
                        <div v-if="item.staffInfos.length>0">
                          <el-tag v-for="staff in item.staffInfos" :key="staff.reconditionStaffId" style="margin-right: 4px;">
                            {{ staff.staffName }}
                          </el-tag>
                        </div>
                        <div v-else style="color: #909399;text-align: center;">无检修人员</div>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12"></el-col>
                </el-row>
                <div class="form-title config-title">问题文字描述</div>
                <el-form-item label-width="20px">{{ item.description }}</el-form-item>
                <div class="form-title config-title">检修方案（审核人员填）</div>
                <el-form-item label-width="20px">{{ item.plan }}</el-form-item>
                <div class="form-title config-title">检修结果（检修人员提交）</div>
                <el-form-item label-width="20px">
                  {{ item.resultInfo.resultContent ? item.resultInfo.resultContent : '（空）' }}
                </el-form-item>
                <div class="form-title config-title">检修图片（检修人员提交）</div>
                <div style="margin-bottom: 20px;" v-if="item.resultInfo.fileUrls">
                  <el-image 
                    class="images"
                    v-for="(itemImg,indexImg) in item.resultInfo.fileUrls"
                    :key="indexImg"
                    :src="itemImg"
                    :preview-src-list="[itemImg]">
                  </el-image>
                </div>
                <div v-else>
                  <el-form-item label="" label-width="20px">（空）</el-form-item>
                </div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="提交人：">{{ item.resultInfo.submitter ? item.resultInfo.submitter : '（无）' }}</el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="提交时间：">
                      {{ item.resultInfo.completeTime ? dayjs(item.resultInfo.completeTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
                    </el-form-item>
                  </el-col>
                </el-row>
                <div class="form-title config-title">检修结果审核</div>
                <el-form-item label-width="20px">
                  {{ item.resultInfo.examine ? item.resultInfo.examine : '（空）' }}
                </el-form-item>
              </div>
            </el-collapse-item>
          </el-collapse>
          
          <div class="form-title">审核意见：
            {{ form.baseInfo.status==2 ? '未解决' 
            : form.baseInfo.status==4 ? '已解决' 
            : '' }}
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="审核人：">{{resultInfo.submitter?resultInfo.submitter:'无'}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="审核时间：">
                {{ form.baseInfo.updateTime ? dayjs(form.baseInfo.updateTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
              </el-form-item>
            </el-col>
          </el-row>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/overhaulRecord')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      active: 3,
      activeNames: [''],
      form: {
        abnormalTaskInfo: {},
        baseInfo: {},
        elementInfos: {},
        patrolDeviceInfo: {}
      },
      resultInfo: {},
      rules: {},
      row: {},
      loading: false
    }
  },
  methods: {
    dayjs,
    // 获取检修计划详情数据
    loadRecordData() {
      this.loading = true
      this.$ajax.post("reconditionRecordDetail", {
        reconditionId: this.row.reconditionId
      }).then((res) => {
        // 默认展示最后一项下拉菜单
        sessionStorage.setItem("detail", true);
        this.activeNames.push((res.data.elementInfos.length).toString())
        this.resultInfo  =  res.data.elementInfos[0].resultInfo
        this.form = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/overhaulRecord");
      return;
    }
    this.row = this.$route.params.row;
    this.loadRecordData()
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1000px;
}
.staffBox {
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #e7e9ee;
}
.config-title {
  font-size: 15px;
}
.images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
</style>